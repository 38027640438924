import React, { useState } from "react";

function LeadForm() {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // API call to save the lead data
  };

  return (
    <section className="form-section">
      <h2>Sign Up for a Lesson</h2>
      <p>
        Ready to get started on your guitar journey? Fill out the form below,
        and we’ll get in touch with you to schedule your first lesson. Whether
        you’re a total beginner or an experienced player, we’re excited to help
        you achieve your goals. Our one-on-one lessons are tailored specifically
        to you, ensuring you get the most out of every session. Don’t wait —
        start playing the music you love today!
      </p>

      <a
        href="https://calendly.com/dvkdesign/guitar-lesson-introductory-phone-call"
        class="btn"
        target="_blank"
      >
        Request Lesson
      </a>
      <p>
        We’ll reach out to you shortly to discuss your availability and get you
        scheduled for your first lesson. Whether it’s classical guitar, rock, or
        something in between, we can’t wait to see what you’ll achieve with our
        custom lessons!
      </p>
    </section>
  );
}

export default LeadForm;

import React from "react";
import "./styles/main.scss";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Benefits from "./components/Benefits";
import Testimonials from "./components/Testimonials";
import LeadForm from "./components/LeadForm";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Benefits />
      <Testimonials />
      <LeadForm />
      <Footer />
    </div>
  );
}

export default App;
